import React from 'react';
import Layout from '../components/global/layout';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import Button from '../components/global/elements/buttons/button';
import sheep from '../static/404.png'

const NotFoundPage = () => (
  <>
    <Header headerStyle="mix" />
    <Layout>
      <SEO title="404: Not found" />
      <div
        className='not-found'
        style={{ 
          backgroundImage: `url(${sheep})` 
        }}
      >
        <div className='site-wide'>
          <div className='not-found-content'>
            <h1>Sorry, my<br></br>baaaaaaaaaad...</h1>
            <p>404 error</p>
            <Button text="This way back to the homepage" link="/" white />
          </div>
        </div>
      </div>
    </Layout>
  </>
);

export default NotFoundPage;
